@use '@shared/util-styling/mixins';

.standalone-section {
  margin-top: var(--spacing-56);
  margin-bottom: var(--spacing-56);

  &--separator-top {
    border-top: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
    padding-top: var(--spacing-56);
    margin-top: 0;
  }

  &--separator-bottom {
    border-bottom: var(--mdrt-border-thickness-01) solid var(--mdrt-color-borders-dark-02);
    padding-bottom: var(--spacing-56);
    margin-bottom: 0;
  }

  h2 {
    font: var(--mdrt-headline-2);
    color: var(--mdrt-color-text-on-light-primary);
    margin-bottom: var(--spacing-8);
  }

  p {
    font: var(--mdrt-body-3-long);
    color: var(--mdrt-color-text-on-light-secondary);
  }
}

.html-block {
  color: var(--mdrt-color-text-on-light-secondary);
  word-break: break-word;

  a {
    font: var(--mdrt-title-3);
    width: fit-content;
    color: var(--mdrt-color-text-on-light-action);
    text-decoration: underline;
    margin-bottom: var(--spacing-12);

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    li {
      color: var(--mdrt-color-text-on-light-secondary);
      font: var(--mdrt-body-3-long);
      list-style-type: disc;
      margin-left: var(--spacing-28);
    }
  }

  strong {
    color: var(--mdrt-color-text-on-light-primary);
    font: var(--mdrt-title-3);
  }

  h3 {
    color: var(--mdrt-color-text-on-light-primary);
    margin-bottom: var(--spacing-8);
  }

  p:not(:last-of-type) {
    margin-bottom: var(--spacing-24);
  }

  &--no-paragraph-gutters {
    p:not(:last-of-type) {
      margin-bottom: 0;
    }
  }

  &--on-dark {
    color: var(--mdrt-color-text-on-dark-secondary);

    h3 {
      color: var(--mdrt-color-text-on-dark-primary);
    }
  }

  &--standalone {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);

    h2 {
      font: var(--mdrt-headline-2);
      color: var(--mdrt-color-text-on-light-primary);
    }

    h3 {
      font: var(--mdrt-headline-3);
      color: var(--mdrt-color-text-on-light-primary);
      margin-bottom: 0;
    }

    h4 {
      font: var(--mdrt-title-1);
      color: var(--mdrt-color-text-on-light-primary);
      margin-bottom: 0;
    }

    p {
      color: var(--mdrt-color-text-on-light-secondary);
    }
  }
}
